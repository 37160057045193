module.exports = {
    isValidEmail: (email) => {
        return /\S+@\S+\.\S+/.test(email)
    },

    isValidUrl: (string) => {
        var result = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (result !== null)
    },

    isValidDate: (string) => {
        const regex = /^20\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/
        return regex.test(string)
    },

    isValidTime: (string) => {
        const regex = /^(0\d|1\d|2[0-4]):([0-5]\d)$/
        return regex.test(string)
    },

    isValidMaxCharacters: (string, maxLength) => {
        return string.length <= maxLength
    },

    isLimitCharacters: (string, maxLength) => {
        return string.length > maxLength
    },

    isValidPhoneNumber: (string) => {
        const regex = /^\d{10}$/
        return regex.test(string)
    },

    trimDateTime: (date) => {
        const months = {
            'Jan': '01',
            'Feb': '02',
            'Mar': '03',
            'Apr': '04',
            'May': '05',
            'Jun': '06',
            'Jul': '07',
            'Aug': '08',
            'Sep': '09',
            'Oct': '10',
            'Nov': '11',
            'Dec': '12'
        }
        if (!date) return null

        const splitDate = date.toString().split(' ');
        return `${splitDate[3]}-${months[splitDate[1]]}-${splitDate[2]}`;
    },

    formPageList: (pageName) => {
        const formList = ['mulesoftmentors', 'research-sign-up', '/community/conferences/new', 'tftlicenses', 'agentforce-research-sign-up'];
        // const formList = [];
        return formList.includes(pageName);
    }
}